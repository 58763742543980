import * as Types from '../../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserSlackOnboardingVariables = Types.Exact<{ [key: string]: never; }>;


export type UserSlackOnboarding = (
  { __typename: 'Query' }
  & { me?: Types.Maybe<(
    { __typename: 'UserModel' }
    & Pick<Types.UserModel, 'email'>
    & { company?: Types.Maybe<(
      { __typename: 'CompanyModel' }
      & Pick<Types.CompanyModel, 'companyKey' | 'integrationType'>
      & { manager: (
        { __typename: 'ManagerModel' }
        & Pick<Types.ManagerModel, 'name' | 'email'>
      ), slackManager?: Types.Maybe<(
        { __typename: 'SlackManagerModel' }
        & Pick<Types.SlackManagerModel, 'name' | 'email' | 'slackUserId'>
      )>, slackConfiguration: (
        { __typename: 'SlackConfigurationModel' }
        & Pick<Types.SlackConfigurationModel, 'isRequired' | 'isRegistered' | 'isInstalled' | 'isConfigured' | 'installUrl'>
      ) }
    )> }
  )> }
);


export const UserSlackOnboardingDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"UserSlackOnboarding"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"me"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"company"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"companyKey"}},{"kind":"Field","name":{"kind":"Name","value":"integrationType"}},{"kind":"Field","name":{"kind":"Name","value":"manager"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"email"}}]}},{"kind":"Field","name":{"kind":"Name","value":"slackManager"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"slackUserId"}}]}},{"kind":"Field","name":{"kind":"Name","value":"slackConfiguration"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"isRequired"}},{"kind":"Field","name":{"kind":"Name","value":"isRegistered"}},{"kind":"Field","name":{"kind":"Name","value":"isInstalled"}},{"kind":"Field","name":{"kind":"Name","value":"isConfigured"}},{"kind":"Field","name":{"kind":"Name","value":"installUrl"}}]}}]}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useUserSlackOnboarding__
 *
 * To run a query within a React component, call `useUserSlackOnboarding` and pass it any options that fit your needs.
 * When your component renders, `useUserSlackOnboarding` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSlackOnboarding({
 *   variables: {
 *   },
 * });
 */
export function useUserSlackOnboarding(baseOptions?: Apollo.QueryHookOptions<UserSlackOnboarding, UserSlackOnboardingVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserSlackOnboarding, UserSlackOnboardingVariables>(UserSlackOnboardingDocument, options);
      }
export function useUserSlackOnboardingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserSlackOnboarding, UserSlackOnboardingVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserSlackOnboarding, UserSlackOnboardingVariables>(UserSlackOnboardingDocument, options);
        }
export type UserSlackOnboardingHookResult = ReturnType<typeof useUserSlackOnboarding>;
export type UserSlackOnboardingLazyQueryHookResult = ReturnType<typeof useUserSlackOnboardingLazyQuery>;
export type UserSlackOnboardingQueryResult = Apollo.QueryResult<UserSlackOnboarding, UserSlackOnboardingVariables>;