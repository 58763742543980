import React, { useEffect } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { AmplitudeEvents, amplitudeService } from 'lib/amplitude';
import styled, { CSSProp } from 'styled-components';
import { PopUpMessage } from 'ui/pop-up-message';
import { getSize } from 'lib/utils';
import { CheckboxIndicator } from '../checkbox-indicator';
import { AlertIcon } from 'ui/icons';
import THEMES from './utils/themes';
import { Filters } from 'amplitude-analytics/enums';

export type CheckboxTheme = 'primary' | 'secondary' | 'bubble' | 'default';

export interface CheckboxProps extends FieldRenderProps<string> {
  label?: string;
  rootCSS?: CSSProp;
  labelCSS?: CSSProp;
  disabled?: boolean;
  tooltip?: string;
  theme?: CheckboxTheme;
  filterType?: Filters;
  checkboxCSS?: CSSProp;
  innerCSS?: CSSProp;
  hasIndicator?: boolean;
}
interface InnerProps {
  $isChecked?: boolean;
  $hasError?: boolean;
  $isDisabled?: boolean;
  $theme: CheckboxTheme;
  $CSS?: CSSProp;
}

const TOOLTIP_CSS = { width: '238px' };

function Checkbox({
  input,
  meta,
  label,
  rootCSS,
  disabled,
  tooltip,
  labelCSS,
  theme = 'default',
  filterType,
  checkboxCSS,
  innerCSS,
  hasIndicator = true,
}: CheckboxProps) {
  const isChecked = input.checked;
  const { error, submitError, touched, dirtySinceLastSubmit, data } = meta;
  const hasError =
    ((error || submitError) && touched && !dirtySinceLastSubmit) || data?.error;

  useEffect(() => {
    if (isChecked && filterType === Filters.therapist_type) {
      amplitudeService.logEvent(AmplitudeEvents.FILTER_ADDED, {
        type: filterType,
        value: label,
      });
    }
  }, [isChecked]);

  return (
    <Wrapper $CSS={rootCSS} $isDisabled={disabled}>
      <Inner
        $theme={theme}
        $isChecked={isChecked}
        $hasError={hasError}
        $isDisabled={disabled}
        $CSS={innerCSS}
      >
        <Input {...input} disabled={disabled} />

        {hasIndicator && (
          <IndicatorStylized
            isChecked={isChecked}
            isDisabled={disabled}
            rootCSS={checkboxCSS}
          />
        )}

        {label && (
          <>
            <Text $CSS={labelCSS}>{label}</Text>
            {tooltip && (
              <Tooltip>
                <AlertIconStylized />
                <PopUpMessage className="tooltip-message" rootCSS={TOOLTIP_CSS}>
                  {tooltip}
                </PopUpMessage>
              </Tooltip>
            )}
          </>
        )}
      </Inner>
      {hasError && <ErrorText>{error || submitError || data?.error}</ErrorText>}
    </Wrapper>
  );
}

const Wrapper = styled.label<{ $CSS?: CSSProp; $isDisabled?: boolean }>`
  display: block;
  ${({ $isDisabled }) => !$isDisabled && 'cursor: pointer;'}

  ${({ $CSS }) => $CSS}
`;

const Inner = styled.div<InnerProps>`
  display: flex;
  align-items: center;

  span {
    border-color: var(
      ${({ $isChecked, $hasError }) => {
        if ($hasError) {
          return '--red';
        } else if ($isChecked) {
          return '--purple5';
        } else {
          return '--purple5';
        }
      }}
    );
  }

  ${({ $isChecked, $isDisabled }) =>
    !$isChecked &&
    !$isDisabled &&
    `
    &:hover {
      span {
        color: var(--purple);
        border-color: var(--purple5);
      }
    }
  `}

  ${({ $theme, $isChecked }) => THEMES[$theme]?.($isChecked) || ''}

  ${({ $CSS }) => $CSS}
`;

const Input = styled.input`
  display: none;
`;

const IndicatorStylized = styled(CheckboxIndicator)`
  margin: 0 ${getSize(8)} 0 0;
`;

const Text = styled.span<{ $CSS?: CSSProp }>`
  position: relative;
  font-weight: 400;
  font-size: ${getSize(12)};
  line-height: ${getSize(18)};
  color: var(--black3);
  white-space: pre-line;

  ${({ $CSS }) => $CSS}
`;

const AlertIconStylized = styled(AlertIcon)`
  width: ${getSize(15)};
  height: ${getSize(15)};
  stroke: var(--gray2);
  cursor: pointer;

  &:hover ~ {
    .tooltip-message {
      display: block;
      animation: showTooltip 0.3s ease-out forwards;
    }
  }
`;

const Tooltip = styled.div`
  position: relative;
  margin-left: ${getSize(9)};
`;

const ErrorText = styled.span`
  margin: ${getSize(2)} 0 0;
  font-weight: 400;
  font-size: ${getSize(10)};
  line-height: ${getSize(16)};
  color: var(--red);
`;

export default Checkbox;
